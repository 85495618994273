import React from 'react'
import { Button } from '@mui/material'

const BasicButton = ({
  children,
  color,
  variant,
  onClick,
  startIcon,
  endIcon,
  className,
  disabled,
  autoFocus,
  size,
  style,
  fullWidth,
  tabIndex,
}) => {
  return (
    <Button
      tabIndex={tabIndex}
      size={size}
      color={color}
      onClick={onClick}
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      className={className}
      disabled={disabled}
      autoFocus={autoFocus}
      style={{fontSize:'18px',...style}}
      fullWidth={fullWidth}
    >
      {children}
    </Button>
  )
}

BasicButton.defaultProps = {
  children: null,
  color: 'primary',
  variant: 'contained',
  startIcon: null,
  endIcon: null,
  className: '',
  disabled: false,
  props: {},
  autoFocus: false,
  size: 'medium',
  style: {},
  fullWidth:false
}

export default BasicButton
