import React from 'react'
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import {unstable_HistoryRouter as HistoryRouter} from 'react-router-dom';

import { history } from './helpers/history'
import Routes from 'routes'

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

import 'assets/css/material-dashboard-react.css?v=1.9.0'
import store from './store'
import { LoadingProvider } from 'providers/ModalLoader'

const theme = createTheme();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LoadingProvider>
          <HistoryRouter history={history}>
            <Routes />
          </HistoryRouter>
        </LoadingProvider>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>
)
