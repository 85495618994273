import React, { useCallback, useState } from 'react'
import { styled } from '@mui/material/styles';
import { Backdrop, CircularProgress } from '@mui/material'
import { LoadingContext } from './'

const PREFIX = 'Loading';
const classes = {
    backdrop: `${PREFIX}-backdrop`,
}
const Root = styled('div')((theme) => ({
    [`& .${classes.backdrop}`]: {
      zIndex: theme.zIndex.drawer + 300,
      color: '#fff',
    },
}))

const LoadingProvider = ({ children }) => {
  const [loading, isLoading] = useState(false)

  const showLoader = useCallback((bool) => {
    isLoading(bool)
  },[])

  return (
    <LoadingContext.Provider value={{ showLoader }}>
      {children}
      <Backdrop className={Root.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </LoadingContext.Provider>
  )
}

export default LoadingProvider
