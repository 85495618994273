import React, { useState,memo, useRef, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types';
import { toast } from "react-toastify";
import { DivRefsContext } from './'
import { TOAST_OPTIONS } from 'helpers/constants'
import { useLocation,matchPath } from 'react-router-dom';
import useLinks from 'config/links.config';
import { ROUTE_PATH_INVENTORY } from 'helpers/constants/url.constants';
import { MODE_EASYDOC,MODE_STOCKPILE,MODE_ORDERWAVE,MODE_ADMIN } from 'helpers/constants';
import { Chip, ListItem, ListItemIcon, ListItemText } from '@mui/material';

function toastbar({toastId='toast-error',type='error',error,message='Error!',undo}){
  const tmp = {...TOAST_OPTIONS,toastId}
  if(undo){
    tmp.closeButton = undo
  }
  let msg = message
  if(error) msg = error.data.status+' : '+error.data.error
  switch (type) {
    case 'error':
      toast.error(msg, tmp);
      break;
    case 'success':
      toast.success(msg, tmp);
      break;
    default:
      break;
  }
  return
}
toastbar.propTypes = {
  toastId: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.object,
  message:PropTypes.string
};


const DivRefsProvider = ({ children }) => {
  const navRef = useRef(null)
  const currentLocation = useRef(null)
  const location = useLocation()
  const formMenuContainer = React.useRef(null); 
  // const [formMenuContainers, setFormMenuContainer] = useState(null)
  const [pageTitle, setPageTitle] = useState('')
  const [replaceTitle, setReplaceTitle] = useState(null)
  const [navHeight, setNavHeight] = useState(0)
  
  const links = useLinks()

  // const {data:formGroups} = useGetFormGroupsQuery(FORM_GROUP_REDUCER_KEY)

  //set selected title based on route path and links config
  useEffect(() => {
    if(replaceTitle || (pageTitle && currentLocation.current && currentLocation.current === location.pathname)) return //skip cause title was set somewhere else
    let selectedLink = null
    let selectedTitle = ''
    //find link based on route path
    if(location.pathname.match(`easydoc`)){
      selectedLink = links[MODE_EASYDOC].find(o=>o.url && o.url.match(location.pathname))
      //find in children
      if(!selectedLink) links[MODE_EASYDOC].map(t=>t.children && t.children.find(o=>{
        if(o.url && o.url.match(location.pathname)) selectedLink = o
        return false
      }))
    }     
    else if(location.pathname.match(`stockpile`)) {
      selectedLink = links[MODE_STOCKPILE].find(o=>o.url && o.url.match(location.pathname))
      //find in children
      if(!selectedLink) links[MODE_STOCKPILE].map(t=>t.children && t.children.find(o=>{
        if(o.url && o.url.match(location.pathname)) {
          selectedLink = o
        } else {
          const invPath = matchPath(
            { path: `${ROUTE_PATH_INVENTORY}/:locationId/:activityAction` },
            location.pathname,
          );
          if(invPath && invPath.params && invPath.params.activityAction){
            if(+invPath.params.locationId === o.id){
              selectedTitle = invPath.params.activityAction
              selectedTitle = selectedTitle[0].toUpperCase() + selectedTitle.substring(1)
              selectedTitle = selectedTitle+' > '+o.text
            }
          }
        }
        return false
      }))
    }
    else if(location.pathname.match(`orderwave`)) selectedLink = links[MODE_ORDERWAVE] && links[MODE_ORDERWAVE].find(o=>o.url && o.url.match(location.pathname))
    else selectedLink = null
    //set title if changed
      // if(selectedLink.color) selectedTitle = selectedLink.color+' '+selectedTitle
    selectedTitle = selectedLink ? <ListItem>
      <ListItemIcon><Chip sx={{
        backgroundColor:selectedLink.color && selectedLink.color,
        color:selectedLink.color?'inherit':'floralwhite',
        }} 
        variant={!selectedLink.color ? 'outlined':'contained'} 
        color={!selectedLink.color ? 'primary':'secondary'}
         icon={selectedLink.icon}></Chip></ListItemIcon> 
      <ListItemText primary={selectedLink.text} />
    </ListItem> :selectedTitle
    
    //update page title
    if(selectedTitle !== pageTitle){
      currentLocation.current = location.pathname
      setPageTitle(selectedTitle)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, links])
  
/**
 * used to set top margin for page
 */
  const handleResize = useCallback(() => {
    if(navRef.current && navHeight !== navRef.current.clientHeight){
      let height = navRef.current.clientHeight
      setNavHeight(height-64)
    }
  },[navHeight]);

  useEffect(() => {//replace page title and block other set
    if(!replaceTitle) return
    setPageTitle(replaceTitle)
  }, [replaceTitle])
  useEffect(() => {//resize after title replace
    if(!pageTitle) return
    handleResize()
}, [handleResize, pageTitle])
  

  return (
    <DivRefsContext.Provider value={{ formMenuContainer,toastbar,
      pageTitle,handleResize,navHeight,navRef,setReplaceTitle }}>
      {children}
    </DivRefsContext.Provider>
  )
}

export default memo(DivRefsProvider)
