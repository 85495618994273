export const API_PATH_BATCH = 'batches'
export const API_PATH_BOL = 'bol'
export const API_PATH_CUSTOMER = 'customers'
export const API_PATH_DASHBOARD = 'dashboard'
export const API_PATH_DEPARTMENT = 'departments'
export const API_PATH_DISTRIBUTOR = 'shipvias'
export const API_PATH_DOC_DASH = 'dashboard'
export const API_PATH_ENTRY = 'entries'
export const API_PATH_ENTRY_ACTIVITY = 'entry-activities'
export const API_PATH_ENTRY_VERIFY = 'entries/verify'
export const API_PATH_EQUIPMENT = 'equipments'
export const API_PATH_EQUIPMENT_CATEGORY = 'equipment-categories'
export const API_PATH_FORM = 'forms'
export const API_PATH_FORM_STAT = 'form-stats'
export const API_PATH_FORM_GROUP = 'form-groups'
export const API_PATH_FORM_LOCATION = 'forms/locations'
export const API_PATH_FUTURE_ORDER = 'orders/upcoming'
export const API_PATH_IMAGE = 'images'
export const API_PATH_INV_ACTIVITY = 'inventory/activities'
export const API_PATH_INV_LOCATION = 'inventory/locations'
export const API_PATH_INV_TOTAL = 'inventory/totals'
export const API_PATH_ITEM = 'items'
export const API_PATH_ITEM_CATEGORY = 'item-categories'
export const API_PATH_LANGUAGE = 'languages'
export const API_PATH_PERMISSION = 'users/permissions'
export const API_PATH_ORDER = 'orders'
export const API_PATH_ORDER_ACTIVITY = 'orders/activities'
export const API_PATH_ORDER_MATRIX = 'orders/matrix'
export const API_PATH_ORDER_VERIFY = 'orders/activities/verify'
export const API_PATH_ORDER_SHEET = 'orders/sheet'
export const API_PATH_PRODLINE = 'prodlines'
export const API_PATH_PRICING = 'pricings'
export const API_PATH_RECALL = 'recall'
export const API_PATH_SETTINGS = 'settings'
export const API_PATH_SHELFLIFE = 'shelflives'
export const API_PATH_SHIP_VIA = 'shipvias'
export const API_PATH_SOP = 'sops'
export const API_PATH_SOP_TYPE = 'sop-types'
export const API_PATH_SHIPPREP = 'shippreps'
export const API_PATH_SHIPPREP_INV = 'shipinv'
export const API_PATH_USER = 'users'


export const ROUTE_PATH_DOC = '/easydoc'
export const ROUTE_PATH_ACCOUNT = '/account'
export const ROUTE_PATH_BATCH = '/stockpile/batches'
export const ROUTE_PATH_BOL = '/stockpile/bol'
export const ROUTE_PATH_CUSTOMER = '/customers'
export const ROUTE_PATH_DASHBOARD = '/admin/dashboard'
export const ROUTE_PATH_DEPARTMENT = ROUTE_PATH_DOC+'/departments'
export const ROUTE_PATH_DISTRIBUTOR = '/distributors'
export const ROUTE_PATH_DOC_DASH = ROUTE_PATH_DOC+'/dash'
export const ROUTE_PATH_ENTRIES = ROUTE_PATH_DOC+'/entries'
export const ROUTE_PATH_ENTRIES_VERIFY = ROUTE_PATH_DOC+'/entry-verify'
export const ROUTE_PATH_ENTRY_ACTIVITY = ROUTE_PATH_DOC+'/entry-activity'
export const ROUTE_PATH_FORM_LOCATION = ROUTE_PATH_DOC+'/locations'
export const ROUTE_PATH_FORM = ROUTE_PATH_DOC+'/forms'
export const ROUTE_PATH_FUTURE_ORDER = '/orderwave/future-orders'
export const ROUTE_PATH_INVENTORY = '/stockpile/inventory'
export const ROUTE_PATH_INV_LOCATION = '/stockpile/locations'
export const ROUTE_PATH_INV_ACTIVITY = '/stockpile/activities'
export const ROUTE_PATH_ITEM = '/items'
export const ROUTE_PATH_ITEM_CATEGORY = '/item-categories'
export const ROUTE_PATH_LANGUAGE = '/languages'
export const ROUTE_PATH_LABEL = 'http://192.168.2.13/labels/index.php'
export const ROUTE_PATH_LOGIN = '/login'
export const ROUTE_PATH_EQUIPMENT = ROUTE_PATH_DOC+'/equipments'
export const ROUTE_PATH_EQUIPMENT_CATEGORY = ROUTE_PATH_DOC+'/equipment-categories'
export const ROUTE_PATH_ORDER_SHEET = '/orderwave/order-sheet'
export const ROUTE_PATH_ORDER_MATRIX = '/orderwave/matrix'
export const ROUTE_PATH_ORDER_ACTIVITY = '/orderwave/activities'
export const ROUTE_PATH_PERMISSION = '/permissions'
export const ROUTE_PATH_PRODLINE = '/prodlines'
export const ROUTE_PATH_RECALL = ROUTE_PATH_DOC+'/recall'
export const ROUTE_PATH_REVISION = ROUTE_PATH_DOC+'/revisions'
export const ROUTE_PATH_SETTINGS = '/admin/settings'
export const ROUTE_PATH_SHELFLIFE = '/shelflives'
export const ROUTE_PATH_SHIP_VIAS = '/shipvias'
export const ROUTE_PATH_SOP = ROUTE_PATH_DOC+'/sops'
export const ROUTE_PATH_SOP_TYPE = ROUTE_PATH_DOC+'/sops/types'
export const ROUTE_PATH_USER = '/users'
