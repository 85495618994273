import React, { useState } from 'react'
import { get } from 'lodash'
import { Controller } from 'react-hook-form'
import { InputAdornment, TextField,MenuItem } from '@mui/material'
import { Cancel } from '@mui/icons-material'

const InputSelect = (
  {
    adormentClasses,
    autoComplete,
    autoFocus,
    className,
    control,
    endAdornment,
    errors,
    disabled,
    defaultValue,
    label,
    name,
    rules,
    type,
    variant,
    style,
    helperText,
    startAdornment,
    isClearable,
    clearIcon,
    inputProps,
    options,
    clearIconPosition,
    afterClearDefaultValueIs,
    onClear,
    selectProps,
    fullWidth
  },
  props
) => {
  // const error = errors[name]
  const error = get(errors,name)
  const [value, setValue] = useState(defaultValue)

  const clear = () => {
    setValue(afterClearDefaultValueIs)
    onClear({ [name]: afterClearDefaultValueIs })
  }

  const adormentStyle = { opacity: disabled ? 0.5 : 1 }

  const Adorment = () => {
    return (
      <InputAdornment
        position={
          startAdornment || clearIconPosition === 'start' ? 'start' : 'end'
        }
        className={adormentClasses}
      >
        {startAdornment || clearIconPosition === 'start' ? (
          <span
            onClick={disabled || !isClearable ? null : clear}
            style={adormentStyle}
          >
            {startAdornment || clearIcon}
          </span>
        ) : (
          <span
            onClick={disabled || !isClearable ? null : clear}
            style={adormentStyle}
          >
            {endAdornment || clearIcon}
          </span>
        )}
      </InputAdornment>
    )
  }

  return (
    <Controller
      name={name}
      render={({ field }) => (
          <TextField select
          fullWidth
          {...field}
          id={name}
          type={type}
          label={label}
          variant={variant}
          helperText={
            error ? (
              <span role='alert'>{error.message || 'This is required'}</span>
            ) : (
              helperText
            )
          }
          autoComplete={autoComplete && name}
          className={className}
          style={style}
          error={error ? true : false}
          InputProps={{
            endAdornment: ((isClearable && clearIconPosition === 'end') ||
              endAdornment) && <Adorment />,
            startAdornment: ((isClearable && clearIconPosition === 'start') ||
              startAdornment) && <Adorment />,
              style:{fontSize:'20px',lineHeight:'40px',marginTop:'24px'},
            ...inputProps,
          }}
          SelectProps={{
            ...selectProps
          }}
          disabled={disabled}
          InputLabelProps={{style: {fontSize: '28px'}}}
        >
        {options.map((option, key) => (
            option &&
            <MenuItem key={key} value={option.value}><em>{option.label}</em></MenuItem>
        ))}
        </TextField>
      )}
      control={control}
      rules={rules}
      defaultValue={value}
      autoFocus={autoFocus}
      {...props}
    />
  )
}

InputSelect.defaultProps = {
  name: '',
  label: '',
  control: {},
  rules: {},
  errors: {},
  type: 'text',
  variant: 'standard',
  defaultValue: '',
  className: '',
  style: {},
  autoComplete: '',
  autoFocus: false,
  helperText: null,
  disabled: false,
  adormentClasses: '',
  startAdornment: null,
  endAdornment: null,
  inputProps: {},
  selectProps:{},
  props: {},
  isClearable: false,
  clearIconPosition: 'end',
  clearIcon: <Cancel color='action' cursor='default' />,
  afterClearDefaultValueIs: 0,
  options:[]
}

export default InputSelect
