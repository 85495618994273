import { useNavigate, useLocation, NavLink } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles';
import { Collapse } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';

import useLinks from 'config/links.config.js'
import { ROUTE_PATH_DOC_DASH,ROUTE_PATH_INVENTORY,ROUTE_PATH_LOGIN } from 'helpers/constants/url.constants';
import SwitchMenu from './SwitchMenu'
import { MODE_EASYDOC,MENU_STORAGE_KEY } from 'helpers/constants';
import { ROUTE_PATH_ENTRIES } from 'helpers/constants/url.constants';

const ExpandableLink=({text,icon,children,navigate,expanded,handleExpand,pathname})=>{
  return <>
  <ListItem button onClick={handleExpand}>
    <ListItemIcon>{icon}</ListItemIcon><ListItemText primary={text} />
    {expanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
  </ListItem>
  {children &&(
    <Collapse in={expanded} timeout='auto' unmountOnExit>
      {children.map(({ text:childText,icon:childIcon,url:childUrl }) => (
        <List
          component='div'
          disablePadding
          key={childText}
          onClick={() => {
            navigate(childUrl)
          }}
        >
          <NavLink to={childUrl}>
            <ListItem button sx={{marginLeft:'20px'}} selected={pathname.includes(childUrl)}>
              <ListItemIcon> {childIcon} </ListItemIcon>
              <ListItemText primary={childText} />
            </ListItem>
          </NavLink>
        </List>
      ))}
    </Collapse>
  )}
  </>
}

const RegularLink=({text,icon,url,navigate,pathname,isExternal})=>{
  if(isExternal){
    return <a href={url}><ListItem>
      <ListItemIcon>{icon}</ListItemIcon><ListItemText primary={text} />
      </ListItem></a>
  } else {
  return <NavLink to={url}><ListItem selected={pathname.includes(url)} >
              <ListItemIcon>{icon}</ListItemIcon><ListItemText primary={text} />
  </ListItem></NavLink>
  }
}

export default function Sidebar({open,handleDrawerClose,drawerWidth,DrawerHeader}) {
  const theme = useTheme();
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [entriesExpanded, setEntriesExpanded] = useState(false)
  const [invExpanded, setInvExpanded] = useState(false)
  const [menuMode,setMenuMode]= useState(localStorage.getItem(MENU_STORAGE_KEY) ?? MODE_EASYDOC)

  useEffect(() => {//expand easy doc links if in doc
    setEntriesExpanded(pathname.includes(ROUTE_PATH_DOC_DASH))
    if(pathname.includes(ROUTE_PATH_ENTRIES)) setEntriesExpanded(pathname.includes(ROUTE_PATH_ENTRIES))
    setInvExpanded(pathname.includes(ROUTE_PATH_INVENTORY))
  }, [pathname])

  const handleEntriesExpand = () => {
    setEntriesExpanded(!entriesExpanded)
  }
  const handleInvExpand = () => {
    setInvExpanded(!invExpanded)
  }

  const handleChangeMode = (modeType)=>{//switch menu mode
    localStorage.setItem(MENU_STORAGE_KEY,modeType)
    setMenuMode(modeType)
  }

  return (
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <SwitchMenu changeMode={handleChangeMode}/>
          <IconButton sx={{flexGrow:0}} onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
        {
          useLinks()[menuMode].map((linkObj,index)=>{
            if(linkObj.children){
              return <ExpandableLink
                key={index}
                text={linkObj.text}
                icon={linkObj.icon}
                children={linkObj.children}
                navigate={navigate}
                expanded={(linkObj.text === 'Entries')?entriesExpanded:invExpanded}
                handleExpand={(linkObj.text === 'Entries')?handleEntriesExpand:handleInvExpand}
                pathname={pathname}
                />
            } else {
              return <RegularLink
                key={index}
                text={linkObj.text}
                icon={linkObj.icon}
                url={linkObj.url}
                navigate={navigate}
                pathname={pathname}
                isExternal={linkObj.isExternal}
                />
            }
          })
        }
        </List>
        <Divider />
        <List>
        <RegularLink
          text={'Log Out'}
          icon={<LogoutTwoToneIcon />}
          url={ROUTE_PATH_LOGIN}
          navigate={navigate}
          pathname={pathname}
          />
        </List>
      </Drawer>
  );
}