export const GENERIC_ADDED_MESSAGE = 'added'

/**
 *Form Text
 */
 export const CREATED_MESSAGE= 'Form Created Successfully!'
 export const DELETED_MESSAGE= 'Form Deleted'
 export const FIELD_ADDED_MESSAGE= 'Field Added'
 export const FIELD_UPDATED_MESSAGE= 'Field Updated'
 export const REQUIRED_PIECE_MESSAGE ='is Required!'
 export const FIELD_REQUIRED_MESSAGE ='Field is Required!'
 export const INCOMPLETE='Form Incomplete'
 export const MIN_VALUE_MESSAGE ='The Minimum is '
export const MAX_VALUE_MESSAGE ='The Maximum is '
export const MIN_LENGTH_MESSAGE ='Minimun 2 Characters Is Required!'
export const MAX_LENGTH_MESSAGE ='Maximum 7 Characters Is Required!'
export const PASSWORD_REQUIRED_MESSAGE ='Password is Required!'
export const UPDATED_MESSAGE= 'Form Updated Successfully!'

/**
 *Bill of Lading Text
 */
export const INSTRUCTION = 'These products must be stored and delivered FROZEN.'

/*
 *Default messages to show when no order found, for export, print, download
 */
export const NO_ORDER_FOUND = {
  PRINT_MESSAGE: `Can't Print No Orders Found`,
  EXPORT_MESSAGE: `Can't Downloaded No Orders Found`,
  PRINT_CARD_MESSAGE: `Can't Print Card. No Orders Found`,
  ORDER_SHEET_MESSAGE: 'No Orders Found!',
  FUTURE_ORDERS_MESSAGE: 'Future Orders Not Placed Yet!',
}

/**
 * product preference types for customers
 */
 export const PREFERENCE_FRESH_MESSAGE = 'Take Fresh'
 export const PREFERENCE_FROZEN_MESSAGE = 'Take Frozen'
 export const PREFERENCE_DELIVERY_CONTAINER_TRAY_MESSAGE = 'Trays'
 export const PREFERENCE_DELIVERY_CONTAINER_BOX_MESSAGE = 'Boxes'

 /**
  * Destination Location types
  */
 export const DESTINATION_DRY_MESSAGE = 'Dry'
 export const DESTINATION_FREEZER_MESSAGE = 'Freezer'

 /**
  * inventory activity types
  */
 export const INV_OUT_TYPE_USE_MESSAGE = 'Use'
 export const INV_OUT_TYPE_SEND_MESSAGE = 'Send'
 export const INV_OUT_TYPE_MOVE_MESSAGE = 'Move'
 export const INV_OUT_TYPE_REMOVE_MESSAGE = 'Remove'
 export const INV_OUT_TYPE_TRASH_MESSAGE = 'Trash'
 export const INV_OUT_TYPE_USED_UP_MESSAGE = 'Finished'
 export const INV_OUT_TYPE_CHANGE_MESSAGE = 'Change'
 export const INV_RETURN_TYPE_DONE_USING_MESSAGE = 'Done Using'
 export const INV_RETURN_TYPE_NOT_SOLD_MESSAGE = 'Not Sold'
 export const INV_RETURN_TYPE_DELETE_ENTRY_MESSAGE = 'Delete'

 /**
  * Customer types
  */

 export const CUSTOMER_TYPE_CUSTOMER_MESSAGE = 'Customer'
 export const CUSTOMER_TYPE_DRIVER_MESSAGE = 'Driver'
 export const CUSTOMER_TYPE_DISTRIBUTOR_MESSAGE = 'Distributor'