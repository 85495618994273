import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import { FORM_GROUP_REDUCER_KEY,ENTRY_REDUCER_KEY,DOC_DASH_REDUCER_KEY,
  BASE_URL,API_VERSION,FORM_STAT_REDUCER_KEY,INV_TOTAL_REDUCER_KEY,FORM_REDUCER_KEY,FORMS_REDUCER_KEY
  ,ORDER_REDUCER_KEY,ORDER_ACTIVITY_REDUCER_KEY,ORDER_SHEET_REDUCER_KEY,BOL_REDUCER_KEY,AUTH_REDUCER_KEY
,TOKEN_STORAGE_KEY,USER_STORAGE_KEY,SHIPPREP_REDUCER_KEY,CUSTOMER_REDUCER_KEY,ACCESS_STORAGE_KEY,REVISION_REDUCER_KEY } from 'helpers/constants'
import { history } from 'helpers/history'



  // import { tokenReceived, loggedOut } from './authSlice'
  
  // create a new mutex
  const mutex = new Mutex()
  const bQuery = fetchBaseQuery({ baseUrl: `${BASE_URL}${API_VERSION}`,
  // prepareHeaders: (headers, { getState }) => {
  //       headers.set('Access-Control-Allow-Origin', '*')
  //       headers.set('Accept', 'application/json')
  //       return headers
  //   }
  mode: "cors"
   })
  const baseQueryWithReauth = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock()
    let result = await bQuery(args, api, extraOptions)
    //replace token
    if(result.data && result.data.token){
      localStorage.setItem(TOKEN_STORAGE_KEY, result.data.token)
      localStorage.setItem(USER_STORAGE_KEY, JSON.stringify({
        id:result.data.user_id,
        language_id:result.data.language_id,
        name:result.data.name,
        username:result.data.username,
      }))
      localStorage.setItem(ACCESS_STORAGE_KEY,JSON.stringify(result.data.access_level))
    }
    // if(result.error){

    //   const messages = Object.keys(result.error.data).map(fieldName=>
    //     (typeof result.error.data[fieldName] !== 'string')?result.error.data[fieldName][0]:'')
    //   console.error('base error ',messages);
    // }
    if (result.error && result.error.status === 401) {
      // checking whether the mutex is locked
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()
        try {
          localStorage.removeItem(TOKEN_STORAGE_KEY)
          localStorage.removeItem(USER_STORAGE_KEY)
          localStorage.removeItem(ACCESS_STORAGE_KEY)
          history.push('/login')
          // const refreshResult = await bQuery(
          //   '/refreshToken',
          //   api,
          //   extraOptions
          // )
          // console.error('refreshResult',refreshResult);
          // if (refreshResult.data) {
          //   // api.dispatch(tokenReceived(refreshResult.data))
          //   // retry the initial query
          //   result = await bQuery(args, api, extraOptions)
          // } else {
          //   // api.dispatch(loggedOut())
          // }
        } finally {
          // release must be called once the mutex should be released again.
          release()
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock()
        result = await bQuery(args, api, extraOptions)
      }
    }
    return result
  }

  /**
   * QUERY
   */
export const rgmpApi = createApi({
  reducerPath:'rgmpApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [FORM_GROUP_REDUCER_KEY,ENTRY_REDUCER_KEY,DOC_DASH_REDUCER_KEY,FORMS_REDUCER_KEY
    ,FORM_STAT_REDUCER_KEY,INV_TOTAL_REDUCER_KEY,FORM_REDUCER_KEY,ORDER_REDUCER_KEY,ORDER_ACTIVITY_REDUCER_KEY
    ,ORDER_SHEET_REDUCER_KEY,BOL_REDUCER_KEY,AUTH_REDUCER_KEY,SHIPPREP_REDUCER_KEY,CUSTOMER_REDUCER_KEY,REVISION_REDUCER_KEY],
  endpoints: () => ({})
})

