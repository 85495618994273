import { useContext,forwardRef } from 'react';
import { pink } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {IconButton,Avatar,Grid, useMediaQuery} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate,useLocation } from 'react-router-dom';
import { ROUTE_PATH_INVENTORY } from 'helpers/constants/url.constants';
import { DivRefsContext } from 'providers/DivRefs';


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Navbar = forwardRef(({
  handleDrawerOpen,open
},ref) => {
  const location = useLocation()
  const navigate = useNavigate()
  const {formMenuContainer,pageTitle} = useContext(DivRefsContext)
  const tinySize = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <AppBar ref={ref} position="fixed" open={open} sx={{pt:1,pb:1,pl:2,pr:2}}>
    <Grid container>
      <Grid item xs={12} md={6} >
        <Box sx={{display:'flex'}}>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
            <MenuIcon />
            </IconButton>
          </Box>
          {
            ((
              location.pathname.match(`/edit|create/([0-9]+)$`) ||
              location.pathname.match(`${ROUTE_PATH_INVENTORY}/([0-9]/)\\w+$`)
              ) && !tinySize) &&
            <Box sx={{ position:'absolute', top:42, left:100  }}>
              <Avatar
              aria-label="go back"
              onClick={()=>{navigate(-1)}}
              sx={{ mr: 2,bgcolor: pink[400],cursor:'pointer'}}
              >
              <ArrowBackIcon />
              </Avatar>
            </Box>
          }
          <Box sx={{ flexGrow: 1 }}>

              <Typography variant="h5" noWrap component="div" sx={{mb:'0px'}}>
              {pageTitle}
              </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ display: 'flex',gap: '10px',justifyContent:'flex-end'}} ref={formMenuContainer} ></Box>
      </Grid>
    </Grid>
    </AppBar>
  );
})

export default Navbar