import {useMemo} from 'react';
import { useLocation } from "react-router-dom";
// import { useAppDispatch, useAppSelector } from './store/hooks'
import { ACCESS_STORAGE_KEY } from 'helpers/constants'
/**
 * empty function
 */
export const emptyFunc = () => {}

/**
 * @param start: milliseconds
 * @param end: milliseconds
 * @return 00:00:00:0000 difference
 */
export const calculteTimeDiff = (start, end) => {
  const startTime = new Date(start) // your initial time
  const endTime = new Date(end) // your later time

  const diff = endTime - startTime
  const miliSecs = 1000, //1 second = 1000 milliseconds
    min = 60 * miliSecs,
    hrs = 60 * min
  const humanDiff = `${Math.floor(diff / hrs)}:${Math.floor(
    (diff % hrs) / min
  ).toLocaleString('en-US', { minimumIntegerDigits: 2 })}:${Math.floor(
    (diff % min) / miliSecs
  ).toLocaleString('en-US', { minimumIntegerDigits: 2 })}.${Math.floor(
    diff % miliSecs
  ).toLocaleString('en-US', { minimumIntegerDigits: 4, useGrouping: false })}`

  return humanDiff
}

/**
 * @param list: array the array from api
 * @param labelCol: string the col to use for label
 * @param valueCol: string the col to use for value
 * @return array with labels and values
 */
export const generateSelectList = (list,labelCol='name',valueCol='id')=>{
  return list.map((child)=>{
    return { label: child[labelCol], value: child[valueCol] }
  })
}

/**
 * @param url: string the address
 * @param callback: function return function
 * @return string of image blob
 */
export const toDataUrl = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
          callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};

/**
 * @param bodyData: object the query parameters
 * @return string of query params
 */
export const bodyDataToQueryString=(bodyData)=>{
  if(typeof bodyData !== 'object') return ''
  let query = [];
  Object.keys(bodyData).map((index)=>{
    if(Array.isArray(bodyData[index])){
      return bodyData[index].map(o=>query.push(`${index}[]=${o}`))
    }
    //continue
    return query.push(`${index}=${bodyData[index]}`)
  })

  return query.join('&');
}

// A custom hook that builds on useLocation to parse
// the query string for you.
/**
 * @return url query parameters
 */
export function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * @return object with booleans for read,write,delete and admin
 */
export function getAccessLevels(){
  return JSON.parse(localStorage.getItem(ACCESS_STORAGE_KEY))
}
