import { Outlet } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import {Box} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { DivRefsContext } from 'providers/DivRefs';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


export default function PersistentDrawerLeft() {
  const { formMenuContainer,navRef,handleResize,navHeight } = useContext(DivRefsContext)
  const [open, setOpen] = useState(false);
  

  const handleDrawerOpen = () => {
    setOpen(true);
    setTimeout(() => { handleResize() }, 10);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setTimeout(() => { handleResize() }, 10);
  };

  useEffect(() => {
    setTimeout(() => { handleResize() }, 10);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return (
    <Box sx={{ display: 'flex'}}>
      <CssBaseline />
      <Navbar 
        ref={navRef}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        formMenuContainer={formMenuContainer}
      />
      <Sidebar 
        open={open}
        handleDrawerClose={handleDrawerClose}
        drawerWidth={drawerWidth}
        DrawerHeader={DrawerHeader}
      />
      {
        formMenuContainer &&
        <Main open={open} sx={{overflow:'auto',marginTop:`${navHeight}px`}} >
        <DrawerHeader />
          <Outlet />
        </Main>
      }
    </Box>
  );
}