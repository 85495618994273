import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Box,
  Chip,
  FormHelperText
} from "@mui/material";
import { Controller } from "react-hook-form";

const InputSelectMulti=({
    name,
    label,
    control,
    rules,
    errors,
    defaultValue,
    helperText,
    disabled,
    options,
  })=> {
    const error = errors[name]
  return (
    <Controller
      name={name}
      control={control}
      type="text"
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControl fullWidth>
          <InputLabel id={name}>{label}</InputLabel>
          <Select
            {...field}
            labelId={name}
            label={name}
            multiple
            defaultValue={[]}
            disabled={disabled}
            rules={rules}
            error={error ? true : false}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((sVal) => (
                  <Chip key={sVal} label={(options.find(o=>o.value.toString() === sVal.toString()))
                    ? options.find(o=>o.value.toString() === sVal.toString()).label : ''} />
                ))}
              </Box>
            )}
          >
            {options.map((option,index) => (
              <MenuItem value={option.value} key={index}>
                <Checkbox checked={field.value.includes(option.value)} />
                 <ListItemText primary={option.label} />
               </MenuItem>
            ))}
          </Select>
          <FormHelperText id="my-helper-text">{
            error ? (
              <span role='alert'>{error.message || 'This is required'}</span>
            ) : (
              <span role='log'>{helperText}</span>
            )
          }</FormHelperText>
        </FormControl>
      )}
    />
  );
}

InputSelectMulti.defaultProps = {
  name: '',
  label: '',
  control: {},
  rules: {},
  errors: {},
  defaultValue: [],
  helperText: '',
  disabled: false,
  options:[],
}
export default InputSelectMulti