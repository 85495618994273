// import React,{useRef} from "react";
import React from "react";
// import MUIRichTextEditor from 'mui-rte'
// import { convertFromHTML, convertFromRaw,ContentState, convertToRaw,EditorState } from 'draft-js'
// import {stateToHTML} from 'draft-js-export-html';
import InputLabel from "@mui/material/InputLabel";

const CustomTextArea = ({label,id,defaultValue,onChange}) => {
  // const [prevValue,setprevValue]=React.useState(defaultValue)
  // const [delayStarted,setDelayStarted]=React.useState(false)
  // const [convertedValue,setConvertedValue]=React.useState('')
  // const ref = useRef()

  // React.useEffect(()=>{
  //   if(!defaultValue){return}
  //   const contentHTML = convertFromHTML(defaultValue)
  //   const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
  //   setConvertedValue(JSON.stringify(convertToRaw(state)))
  //   setprevValue(defaultValue)
  // },[defaultValue])

  // const handleInputChange=()=>{
  //     if(!delayStarted){//wait for a second before saving state
  //       setDelayStarted(true)
  //       setTimeout(function () {
  //         triggerSave()
  //         setDelayStarted(false)//reset delay for next update
  //       }, 3000);
  //     }
  // }

//   const save = (text) => {
//     const newVal = stateToHTML(convertFromRaw(JSON.parse(text)))
//     if(prevValue !== newVal){//only on actual update
//       setprevValue(newVal)
//       onChange(newVal)
//     }
// }
// const triggerSave = () => {
//         ref.current?.save()
//     }
  return(
    <React.Fragment>
      {label !== undefined ? (
        <InputLabel htmlFor={id} >
          {label}
        </InputLabel>
      ) : null}
      {/* <MUIRichTextEditor
        ref={ref}
        defaultValue={defaultValue}
        // onChange={handleInputChange}
        onChange={value => {
          const content = JSON.stringify(
            convertToRaw(value.getCurrentContent())
          );
          onChange(content)
        }}
        onSave={save} label="Start typing..."
        inlineToolbar={true}
      /> */}
    </React.Fragment>
  )
}

CustomTextArea.defaultProps = {
  id: '',
  label: '',
  defaultValue:''
}

export default CustomTextArea
