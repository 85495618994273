import { rgmpApi } from './base.service.js'
import { AUTH_REDUCER_KEY} from 'helpers/constants'
export const authApi = rgmpApi.injectEndpoints({
  endpoints: (builder) => ({
    getAuth: builder.query({
      queryFn: () => ({}),
      // providesTags: [AUTH_REDUCER_KEY],
    }),
    updateAuthUser: builder.mutation({
      queryFn: (credentials, queryApi) => {
        queryApi.dispatch(
          authApi.util.updateQueryData('getAuth', AUTH_REDUCER_KEY, () => {
            return credentials
          })
        )
        return {}
      },
      // invalidatesTags: [AUTH_REDUCER_KEY],
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: 'auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
})

export const { useLoginMutation,useGetAuthQuery,useUpdateAuthUserMutation } = authApi
