import React, { useState } from 'react'
import Switch from '@mui/material/Switch'
import { Controller } from 'react-hook-form'

const ToggleButton = ({
  name,
  label,
  control,
  rules,
  errors,
  defaultValue,
  disabled,
  handleChange,
}) => {
  const [checked, setChecked] = useState(defaultValue)
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={checked}
      render={({
    field: { onChange, onBlur, value, name, ref },
    fieldState: { invalid, isTouched, isDirty, error },
    formState,
  }) => {
        return (
          <label htmlFor={name}>
            {label}
            <Switch
              disabled={disabled}
              defaultValue={defaultValue}
              id={name}
              checked={checked}
              onChange={() => {
                setChecked(!checked)
                handleChange(!checked)
              }}
            />
          </label>
        )
      }}
    />
  )
}

ToggleButton.defaultProps = {
  name: '',
  label: '',
  control: {},
  rules: {},
  defaultValue: false,
  errors: {},
  disabled: false,
}

export default ToggleButton
