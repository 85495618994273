export const handleError = (store) => (next) => (action) => {
  if(!action.error){return next(action)}//skip if no error
  if(action.payload){
    if(action.payload.error){
      // showSnackbar({msg:action.payload.error,type:'error'})
    }
    if(action.payload.data){
      if(action.payload.data.message){
        // showSnackbar({msg:`${action.payload.data.message} Line: ${action.payload.data.line}`,type:'error'})
      }
      if(action.payload.status === 422){//trying to submit invalid data to api
        const messages = Object.keys(action.payload.data).map(fieldName=>
          (typeof action.payload.data[fieldName] !== 'string')?action.payload.data[fieldName][0]:'')
          console.error('inside error ',messages);
        // store.dispatch(showSnackbar({msg:`${messages.toString()}`,type:'error'}))
      }
    }
  }//check for payload -->
  return next(action)
}//handleError -->
