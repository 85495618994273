export const FIELDTYPE_CAMERA= 'camera'
export const FIELDTYPE_CHECKBOX= 'checkbox'
export const FIELDTYPE_DAMAGED= 'damaged'
export const FIELDTYPE_DATE= 'date'
export const FIELDTYPE_EXTERNAL= 'external'
export const FIELDTYPE_INCREMENT='increment'
export const FIELDTYPE_LISTITEM= 'listitem'
export const FIELDTYPE_MATH_AVERAGE='mathaverage'
export const FIELDTYPE_MATH_DIFFERENCE='mathdifference'
export const FIELDTYPE_MATH_MULTIPLY='mathmultiply'
export const FIELDTYPE_MATH_TOTAL='mathtotal'
export const FIELDTYPE_NUMBER= 'inputnum'
export const FIELDTYPE_PASSFAIL= 'passfail'
export const FIELDTYPE_SATISFACTORY= 'satisfactory'
export const FIELDTYPE_TABLE_ROW='tablerow'
export const FIELDTYPE_TEXT= 'input'
export const FIELDTYPE_TIME= 'time'
export const FIELDTYPE_TITLE= 'title'
export const FIELDTYPE_THUMBNAIL='imglink'
export const FIELDTYPE_YESNO= 'yesno'

export const CORRECTIVE_OPTION_VALS =['no','damaged','missing','fail','bad']

export const FORM_FIELDS = {
  [FIELDTYPE_CAMERA]: {
    label : 'Camera',
    value : FIELDTYPE_CAMERA
  },
  [FIELDTYPE_CHECKBOX]: {
    label : 'Checkbox',
    value : FIELDTYPE_CHECKBOX
  },
  [FIELDTYPE_DAMAGED]: {
    label : 'Damaged',
    value : FIELDTYPE_DAMAGED
  },
  [FIELDTYPE_DATE]: {
    label : 'Date',
    value : FIELDTYPE_DATE
  },
  [FIELDTYPE_EXTERNAL]: {
    label: 'Reference',
    value: FIELDTYPE_EXTERNAL
  },
  [FIELDTYPE_INCREMENT]: {
    label : 'Increment',
    value : FIELDTYPE_INCREMENT
  },
  [FIELDTYPE_TEXT]: {
    label : 'Text Field',
    value : FIELDTYPE_TEXT
  },
  [FIELDTYPE_NUMBER]: {
    label : 'Number Field',
    value : FIELDTYPE_NUMBER
  },
  [FIELDTYPE_LISTITEM]: {
      label : 'List Item',
      value : FIELDTYPE_LISTITEM
  },
  [FIELDTYPE_MATH_AVERAGE]: {
    label : 'Average',
    value : FIELDTYPE_MATH_AVERAGE
  },
  [FIELDTYPE_MATH_DIFFERENCE]: {
    label : 'Difference',
    value : FIELDTYPE_MATH_DIFFERENCE
  },
  [FIELDTYPE_MATH_MULTIPLY]: {
    label : 'Multiply',
    value : FIELDTYPE_MATH_MULTIPLY
  },
  [FIELDTYPE_MATH_TOTAL]: {
    label : 'Total',
    value : FIELDTYPE_MATH_TOTAL
  },
  [FIELDTYPE_PASSFAIL]: {
    label : 'Pass/Fail',
    value : FIELDTYPE_PASSFAIL
  },
  [FIELDTYPE_SATISFACTORY]: {
      label : 'Satisfactory',
      value : FIELDTYPE_SATISFACTORY
  },
  [FIELDTYPE_TABLE_ROW]: {
    label : 'Table',
    value : FIELDTYPE_TABLE_ROW
  },
  [FIELDTYPE_TIME]: {
    label : 'Time',
    value : FIELDTYPE_TIME
  },
  [FIELDTYPE_TITLE]: {
      label : 'Title',
      value : FIELDTYPE_TITLE
  },
  [FIELDTYPE_THUMBNAIL]: {
    label : 'Thumbnail',
    value : FIELDTYPE_THUMBNAIL
  },
  [FIELDTYPE_YESNO]: {
    label : 'Yes/No',
    value : FIELDTYPE_YESNO
  },
};

export const TYPES_WITH_NO_INPUT = [
  FORM_FIELDS[FIELDTYPE_INCREMENT],
  FORM_FIELDS[FIELDTYPE_LISTITEM],
  FORM_FIELDS[FIELDTYPE_TITLE],
  FORM_FIELDS[FIELDTYPE_THUMBNAIL],
];
export const TYPES_WITH_INPUT = [
  FORM_FIELDS[FIELDTYPE_CHECKBOX],
  FORM_FIELDS[FIELDTYPE_DATE],
  FORM_FIELDS[FIELDTYPE_NUMBER],
  FORM_FIELDS[FIELDTYPE_TEXT],
  FORM_FIELDS[FIELDTYPE_TIME],
];
export const TYPES_WITH_CORRECTIVE_ACTION =[
  FORM_FIELDS[FIELDTYPE_DAMAGED],
  FORM_FIELDS[FIELDTYPE_PASSFAIL],
  FORM_FIELDS[FIELDTYPE_SATISFACTORY],
  FORM_FIELDS[FIELDTYPE_YESNO],
]

export const TYPES_WITH_EXTERNAL_REFERENCES = [
    FORM_FIELDS[FIELDTYPE_EXTERNAL],
    FORM_FIELDS[FIELDTYPE_TABLE_ROW],
];

export const TYPES_WITH_MATH = [
    FORM_FIELDS[FIELDTYPE_MATH_AVERAGE],
    FORM_FIELDS[FIELDTYPE_MATH_DIFFERENCE],
    FORM_FIELDS[FIELDTYPE_MATH_MULTIPLY],
    FORM_FIELDS[FIELDTYPE_MATH_TOTAL],
]

export const FILTER_TYPE_BATCH= 'prodbatches'
export const FILTER_TYPE_COMPLETED_TODAY = 'completedtoday'
export const FILTER_TYPE_COMPLETED_TODAY_NOT_LINKED = 'completedtodaynotlinked'
export const FILTER_TYPE_COMPLETED_YESTERDAY_NOT_LINKED = 'completedyesterdaynotlinked'
export const FILTER_TYPE_CUSTOMER= 'customers'
export const FILTER_TYPE_ITEM = 'items'
export const FILTER_TYPE_ITEM_CATEGORY = 'itemcat'
export const FILTER_TYPE_EQUIPMENT= 'equipments'
export const FILTER_TYPE_EQUIPMENT_CATEGORY = 'equipmentcat'
export const FILTER_TYPE_NOT_LINKED = 'notlinked'
export const FILTER_TYPE_TODAY = 'today'
export const FILTER_TYPE_TODAY_NOT_LINKED = 'todaynotlinked'
export const FILTER_TYPE_YESTERDAY_NOT_LINKED = 'yesterdaynotlinked'
export const FILTER_TYPE_SHIPPING= 'shipping'
export const FILTER_TYPE_INVENTORY= 'inventory'
export const FILTER_TYPE_INVENTORY_LOCATIONS= 'locations'
export const FILTER_TYPE_ORDERS= 'orders'
export const FILTER_TYPE_PRODUCTION_LINES= 'prodlines'
export const FILTER_TYPE_FORM_LOCATIONS= 'doclocations'

export const FILTER_SELECT_LIST = [
    {label: '* Equipment Category', value: FILTER_TYPE_EQUIPMENT_CATEGORY},
    {label: '* Items', value: FILTER_TYPE_ITEM},
    {label: '* Item Category', value: FILTER_TYPE_ITEM_CATEGORY},
    {label: '* Not Referenced Yet', value: FILTER_TYPE_NOT_LINKED},
    {label: '* Timer Completed Today', value: FILTER_TYPE_COMPLETED_TODAY},
    {label: '* Timer Completed Today & Not Referenced Yet', value: FILTER_TYPE_COMPLETED_TODAY_NOT_LINKED},
    {label: '* Timer Completed Yesterday & Not Referenced Yet', value: FILTER_TYPE_COMPLETED_YESTERDAY_NOT_LINKED},
    {label: '* Today', value: FILTER_TYPE_TODAY},
    {label: '* Today & Not Referenced Yet', value: FILTER_TYPE_TODAY_NOT_LINKED},
    {label: '* Yesterday & Not Referenced Yet', value: FILTER_TYPE_YESTERDAY_NOT_LINKED},
]

export const EXTERNAL_SELECT_LIST = [
    {label: '* Batch Mixes', value: FILTER_TYPE_BATCH},
    {label: '* Bill of Lading', value: FILTER_TYPE_SHIPPING},
    {label: '* Customers', value: FILTER_TYPE_CUSTOMER},
    {label: '* Equipment', value: FILTER_TYPE_EQUIPMENT},
    {label: '* Equipment Categories', value: FILTER_TYPE_EQUIPMENT_CATEGORY},
    {label: '* Inventory', value: FILTER_TYPE_INVENTORY},
    {label: '* Inventory Locations', value: 'locations'},
    {label: '* Items', value: FILTER_TYPE_ITEM},
    {label: '* Item Categories', value: FILTER_TYPE_ITEM_CATEGORY},
    {label: '* Orders', value: FILTER_TYPE_ORDERS},
    {label: '* Production Lines', value: FILTER_TYPE_PRODUCTION_LINES},
    {label: '* Production Locations', value: FILTER_TYPE_FORM_LOCATIONS},
]

export const  FILTER_CUSTOMER_SELECT_LIST =[
  {label: 'With Orders Made', value: 'ordered'},
  {label: 'With Shipping Prepared', value: 'shipprepped'}
]
export const  FILTER_INVENTORY_SELECT_LIST =[
  {label: 'Used', value: 'usedlotcodes'},
  {label: 'Added', value: 'invadded'},
  {label: 'Totals', value: 'totals'}
]
export const  FILTER_ORDER_SELECT_LIST =[
  {label: 'With Shipping Prepared', value: 'shipprepped'}
]
export const  FILTER_SHIPPING_SELECT_LIST =[
  {label: 'Customer', value: 'customer'},
  {label: 'Not Shipped', value: 'noshipped'}
]

export const FIELD_OPTION_TYPES = {
  FIELDTYPE_OPTION_IMAGE: 'img',
  FIELDTYPE_OPTION_DATE: 'date',
  FIELDTYPE_OPTION_DAY_OFFSET:'day_offset',
  FIELDTYPE_OPTION_INCREMENT:'increment',
  FIELDTYPE_OPTION_MATH_MULTIPLY: 'math_multiply',
  FIELDTYPE_OPTION_MATH_AVERAGE: 'math_average',
  FIELDTYPE_OPTION_MATH_DIFFERENCE: 'math_difference',
  FIELDTYPE_OPTION_MATH_TOTAL: 'math_total',
  FIELDTYPE_OPTION_NUMBER_MODTYPE: 'modtype',
  FIELDTYPE_OPTION_NUMBER_MODVAL:'modval',
  FIELDTYPE_OPTION_NUMBER_TYPE: 'numbertype',
  FIELDTYPE_OPTION_DEFAULT:'default_value',
  FIELDTYPE_OPTION_VERIFY_USER: 'showverifyuser',
  FIELDTYPE_OPTION_USER: 'showuser',
  FIELDTYPE_OPTION_PREVIOUS: 'autoprev'
};
export const filterFormTypes = {
  FILTERTYPE_PRODUCTION_BATCHES: 'prodbatches',
  FILTERTYPE_SHIPPING: 'shipping',
  FILTERTYPE_CUSTOMERS: 'customers',
  FILTERTYPE_EQUIPMENTS: 'equipments',
  FILTERTYPE_EQUIPMENT_CATEGORIES: 'equipmentcategories',
  FILTERTYPE_INVENTORY: 'inventory',
  FILTERTYPE_INVENTORY_LOCATIONS: 'locations',
  FILTERTYPE_ITEMS: 'items',
  FILTERTYPE_ITEM_CATEGORIES: 'itemcategories',
  FILTERTYPE_ORDERS: 'orders',
  FILTERTYPE_PRODUCTION_LINES: 'prodlines',
  FILTERTYPE_FORM_LOCATIONS: 'doclocations'
};

export const correctiveTextOptionVals =['no','damaged','missing','fail','bad']

export const errorMessages = {
    defaultValue: 'Default Value is required.',
    offSet: 'Offset by Days is required.',
    label: 'Label field is required.'
};

export const formEventsActionOptions = [
    {label: 'Remove from Inventory', value: 'inv-return'},
    {label: 'Out from Inventory', value: 'inv-out'},
    {label: 'Add to Inventory', value: 'inv-add'},
    {label: 'Ship Order', value: 'ship'},
    {label: 'Notify Others', value: 'notify'},
    {label: 'Create Entry', value: 'entry'},
];

export const FORM_EVENT_TYPE_INV_RETURN = 'inv-return'
export const FORM_EVENT_TYPE_INV_OUT = 'inv-out'
export const FORM_EVENT_TYPE_INV_ADD = 'inv-add'
export const FORM_EVENT_TYPE_SHIP = 'ship'
export const FORM_EVENT_TYPE_NOTIFY = 'notify'
export const FORM_EVENT_TYPE_ENTRY = 'entry'

export const FORM_EVENT_TYPE_SELECT_LIST = [
    {label: 'Remove from Inventory', value: FORM_EVENT_TYPE_INV_RETURN},
    {label: 'Out from Inventory', value: FORM_EVENT_TYPE_INV_OUT},
    {label: 'Add to Inventory', value: FORM_EVENT_TYPE_INV_ADD},
    {label: 'Ship Order', value: FORM_EVENT_TYPE_SHIP},
    {label: 'Notify Others', value: FORM_EVENT_TYPE_NOTIFY},
    {label: 'Create Entry', value: FORM_EVENT_TYPE_ENTRY},
];

export const MEASUREMENT_SELECT_LIST = [
    {label: 'Quantity', value: 'qty'},
    {label: 'Pounds LB', value: 'weight'},
];

export const FORM_INV_EVENT_TYPE_SELECT_LIST = [
    {label: 'use', value: 0},
    {label: 'send', value: 1},
    {label: 'move', value: 2},
    {label: 'remove', value: 3},
    {label: 'trash', value: 4},
    {label: 'used up', value: 5},
    {label: 'change', value: 6},
    {label: 'prep', value: 7},
];

export const NOTIFY_CONDITION_SELECT_LIST = [
    {label: 'Anytime', value: 'anytime'},
    {label: 'When Corrective Fields are Filled', value: 'when corrective fields are filled'},
];

export const INTERVAL_TYPES = {
  1: ['Whenever','Daily','Weekly','Monthly','Annually'],
  2: ['Cuando sea','Diaria','Semanalmente','Mensual','Anual']
  
}
export const FORM_INTERVAL_TYPES = [...INTERVAL_TYPES[1],"After another form"];

export const formObject = {
    id:null,
    name: '',
    form_group_id: 1,
    interval_type: 0,
    required_after: 0,
    manager_id: 0,
    must_verify: false,
    user_id: 0,
    followup_id: 0,
    is_batch: false,
    use_timer: false,
    is_active: false,
}

export const fieldObject ={
  form_id: 0,
  stat_group_field_id: 0,
  type: '',
  is_mandatory: false,
  rowpos: 0,
  colpos:0
}

export const eventObject = {
    id: '',
    type: '',
    fields: {
        inventory_location_id: '',
        item_id: '',
        code: '',
        amount: '',
        mfg: '',
        exp: '',
        type: '',
        shipprep_id: '',
        order_id: '',
        user_permission_id: '',
        user_id: '',
        corrective: '',
        form_id: '',
    },
}

export const getKey=(fields,fieldId,dupeId)=>{
  return Object.keys(fields).find(index=>
    fields[index]
    ? fields[index].form_field_id
      ? +fields[index].form_field_id === +fieldId && +fields[index].dupe_id === +dupeId
      : +fields[index].id === +fieldId
    : false
    )
}