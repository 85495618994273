// export * from './message.constants';
// export * from './ordersheet.constants';
import * as FORM from './form.constants';
import * as URL from './url.constants';
import * as MESSAGES from './message.constants';
export {
   FORM,
   URL,
   MESSAGES
};

export const MATRIX_MODE_QTY={value:'qty',label:'Qty'}
export const MATRIX_MODE_PRICE={value:'price',label:'Price'}

export const DAYS_NAME = {
  0: { default: 'Sunday', short: 'Sun' },
  1: { default: 'Monday', short: 'Mon' },
  2: { default: 'Tuesday', short: 'Tue' },
  3: { default: 'Wednesday', short: 'Wed' },
  4: { default: 'Thursday', short: 'Thu' },
  5: { default: 'Friday', short: 'Fri' },
  6: { default: 'Saturday', short: 'Sat' },
}
export const MAXIMUM_NOTICATIONS = 3 //maximunm number of notifications to show
/**
 * Config to be moved to api
 */
export const WEBSITE_CONFIG = {
  verificationType: 'signature', //added two types click(default) or signature
}
export const COMPANY_NAME = 'Rapid GMP'
export const COMPANY_ADDRESS = '3559 Greenwood Ave. Commerce CA, 90040'
export const COMPANY_PHONE = '424-835-0195'

/**
 * mode options
 */
export const MODE_EASYDOC = 'Easy Doc'
export const MODE_STOCKPILE = 'Stockpile'
export const MODE_ORDERWAVE = 'Orderwave'
export const MODE_ADMIN = 'Admin'
/**
 * Site Specific constants
 */
export const TOKEN_STORAGE_KEY = 'rgmp_token'
export const USER_STORAGE_KEY = '__u__'
export const ACCESS_STORAGE_KEY = 'rgmp_access_levels'
export const MENU_STORAGE_KEY = 'rgmp_menu_mode'

/**
 * API URL OR Version
 */
export const API_VERSION = '/api/v1'
export const BASE_URL = 'https://api.rapidgmp.com'
// export const BASE_URL = 'http://192.168.86.113'
export const UPLOAD_DIR_ENTRIES = "uploads/entries/"
export const UPLOAD_DIR_FORMS = "uploads/forms/"
export const UPLOAD_DIR_SITE = "uploads/site/"

/**
 * redux reducers key
 * help in useSelector of states and creation of reducers
 */
export const AUTH_REDUCER_KEY = 'auth'
export const BATCH_REDUCER_KEY = 'batch'
export const BOL_REDUCER_KEY = 'bol'
export const CUSTOMER_REDUCER_KEY = 'customer'
export const DASHBOARD_REDUCER_KEY = 'dashboard'
export const DEPARTMENT_REDUCER_KEY = 'department'
export const DISTRIBUTOR_REDUCER_KEY = 'distributor'
export const DOC_DASH_REDUCER_KEY = 'docdash'
export const ENTRY_REDUCER_KEY = 'entry'
export const EQUIPMENT_REDUCER_KEY = 'equipment'
export const EQUIPMENT_CATEGORY_REDUCER_KEY = 'equipmentCategory'
export const FORMS_REDUCER_KEY = 'forms'
export const FORM_REDUCER_KEY = 'form'
export const FORM_GROUP_REDUCER_KEY = 'formgroup'
export const FORM_LOCATION_REDUCER_KEY = 'formLocation'
export const FORM_STAT_REDUCER_KEY = 'formstat'
export const INV_TOTAL_REDUCER_KEY = 'invTotal'
export const INV_ACTIVITY_REDUCER_KEY = 'invActivity'
export const INV_LOCATION_REDUCER_KEY = 'invlocation'
export const ITEM_REDUCER_KEY = 'item'
export const ITEM_CATEGORY_REDUCER_KEY = 'itemcategory'
export const LOGIN_REDUCER_KEY = 'login'
export const LANGUAGE_REDUCER_KEY = 'language'
export const MUTATION_FULFILLED_KEY = 'api/executeMutation/fulfilled'
export const MUTATION_REJECTED_KEY = 'api/executeMutation/rejected'
export const ORDER_REDUCER_KEY = 'order'
export const ORDER_ACTIVITY_REDUCER_KEY = 'orderactivity'
export const ORDER_SHEET_REDUCER_KEY = 'orderSheet'
export const PERMISSION_REDUCER_KEY = 'permission'
export const POSSIBILITY_REDUCER_KEY = 'possibilities'
export const PRODLINE_REDUCER_KEY = 'prodline'
export const SETTINGS_REDUCER_KEY = 'settings'
export const SHELFLIFE_REDUCER_KEY = 'shelflife'
export const SHIPPREP_REDUCER_KEY = 'shipprep'
export const SNACKBAR_REDUCER_KEY = 'snackbar'
export const REVISION_REDUCER_KEY = 'formRevision'
export const USER_REDUCER_KEY = 'user'
export const SHIP_VIA_REDUCER_KEY = 'shipvia'
export const SOP_REDUCER_KEY = 'sop'
export const SOP_TYPE_REDUCER_KEY = 'sopType'

/**
 * Dashboard Stat Types
 */
 export const STAT_TYPE_SUM = 'sum'
 export const STAT_TYPE_COUNT = 'count'

/**
 * Inventory Out Types
 */
export const INV_OUT_TYPES=[
  {value:0,message:MESSAGES.INV_OUT_TYPE_USE_MESSAGE,isForProduct:false,isForInventory:true},
  {value:1,message:MESSAGES.INV_OUT_TYPE_SEND_MESSAGE,isForProduct:true,isForInventory:false},
  {value:2,message:MESSAGES.INV_OUT_TYPE_MOVE_MESSAGE,isForProduct:true,isForInventory:true},
  {value:3,message:MESSAGES.INV_OUT_TYPE_REMOVE_MESSAGE,isForProduct:false,isForInventory:true},
  {value:4,message:MESSAGES.INV_OUT_TYPE_TRASH_MESSAGE,isForProduct:true,isForInventory:true},
  {value:5,message:MESSAGES.INV_OUT_TYPE_USED_UP_MESSAGE,isForProduct:false,isForInventory:true},
  // {value:6,message:MESSAGES.INV_OUT_TYPE_CHANGE_MESSAGE,isForProduct:false,isForInventory:false}
]
export const INV_RETURN_TYPES=[
  {value:0,message:MESSAGES.INV_RETURN_TYPE_DONE_USING_MESSAGE,isForProduct:false,isForInventory:true},
  {value:1,message:MESSAGES.INV_RETURN_TYPE_NOT_SOLD_MESSAGE,isForProduct:true,isForInventory:false},
  // {value:,message:MESSAGES.INV_RETURN_TYPE_DELETE_ENTRY_MESSAGE,isForProduct:false,isForInventory:true}
]
export const INV_CHANGE_TYPES=[
  {value:0,message:MESSAGES.INV_OUT_TYPE_CHANGE_MESSAGE,isForProduct:false,isForInventory:false}
]

/**
 * Customer Types
 */
export const CUSTOMER_TYPES=[
  {message:MESSAGES.CUSTOMER_TYPE_CUSTOMER_MESSAGE},
  {message:MESSAGES.CUSTOMER_TYPE_DRIVER_MESSAGE},
  {message:MESSAGES.CUSTOMER_TYPE_DISTRIBUTOR_MESSAGE}
]
export const PRODUCT_PREFERENCES=[
  {message:MESSAGES.PREFERENCE_FRESH_MESSAGE},
  {message:MESSAGES.PREFERENCE_FROZEN_MESSAGE},
]
export const DELIVERY_CONTAINER_TYPES=[
  {message:MESSAGES.PREFERENCE_DELIVERY_CONTAINER_TRAY_MESSAGE},
  {message:MESSAGES.PREFERENCE_DELIVERY_CONTAINER_BOX_MESSAGE},
]

/**
 * Item category types
 */
 export const ITEM_CATEGORY_TYPES = {
  PRODUCT: 'product',
  INVENTORY: 'inventory',
  PACKAGING: 'packaging',
  BAG: 'bag',
  CONTAINER: 'container',
  LABEL:'label',
}

/**
 * Tabulator options
 */
export const TABLE_OPTIONS = {
  height:window.innerHeight-250,
  downloadReady: (fileContents, blob) => blob,
  printRowRange:'all',
  layout: "fitData",
  progressiveLoad: 'load',//scroll has error in the version of tabulator used by react-tabulator
  progressiveLoadDelay: 200,
  progressiveLoadScrollMargin: 30,
  // pagination:true,
  paginationSize: 400,
  // ajaxResponse: (url, params, response) => {
  //   return {
  //     data: response.data,
  //     last: response.last_page
  //   };
  // },
  // ajaxError: function (error) {
  //   console.error('ajaxError', error);
  // }
}

/**
 * Snacbar positions
 */
export const ANCHOR_ORIGIN = {
  tc: { vertical: 'top', horizontal: 'center' },
  tr: { vertical: 'top', horizontal: 'right' },
  tl: { vertical: 'top', horizontal: 'left' },
  bc: { vertical: 'bottom', horizontal: 'center' },
  br: { vertical: 'bottom', horizontal: 'right' },
  bl: { vertical: 'bottom', horizontal: 'left' },
}

/**
 * Form Menu position
 */
 export const FORM_MENU_ANCHOR_ORIGIN = { vertical: 'top', horizontal: 'right' }

/**
 * Validations Rules for react-hook-form
 */
 export const FIELD_REQUIRED_RULE =(message=null)=> (
   {required: message ?? MESSAGES.FIELD_REQUIRED_MESSAGE}
)
export const TEXT_INPUT_MIN_LENGTH_RULE = {
  minLength: {
    value: 2,
    message: MESSAGES.MIN_LENGTH_MESSAGE,
  },
}
export const TEXT_INPUT_MAX_LENGTH_RULE = {
  maxLength: {
    value: 7,
    message: MESSAGES.MAX_LENGTH_MESSAGE,
  },
}

export const PASSWORD_INPUT_MIN_LENGTH_RULE = {
  minLength: {
    value: 1,
    message: MESSAGES.PASSWORD_REQUIRED_MESSAGE,
  },
}

/**
 * Toast Options
 */

export const TOAST_OPTIONS = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  newestOnTop:true,
};

export const OLD_MESSAGES = {
  loginFailure: 'Incorrect Username and Password combination. Please try again.',
  error: 'Something went wrong. Please try again later.',
  formDeleted: 'Form has been removed Successfully!',
  limit: 'You can only add 3 columns in a row!',
  inventoryUpdated:'Inventory Updated',
  pricingOverlap:'Pricings must have unique dates',
  fullAddress:'Must fill out entire address',
  noActives:'Need 1 active'
};

export const FILE_NAME = 'Orders Sheet'
export const FILE_TYPE = 'csv'

/* Order Sheet Header Options all is set true.
 * In future or updradations is in progress in following sections, so set false so it not render in order page.
 * If any option that's no need set false.
 */
export const ORDER_SHEET_HEADERS_OPTIONS = {
  goBack: false,
  selectCustomers: true,
  printTable: true,
  printSingle: true,
  hasExport: true,
  totalOrders: true,
  hasFutureOrder: true,
  hasVerifyAll: true,
  datePicker: true,
}

export const FUTURE_ORDERS_HEADERS_OPTIONS = {
  goBack: true,
  selectCustomers: false,
  printSingle: false,
  totalOrders: false,
  hasFutureOrder: false,
  hasVerifyAll: false,
  datePicker: false,
}
/**
 * Shelf life types
 */
 export const SHELF_LIFE_TYPES = [
  'Store Dry',
  'Store Freezer',
  'Ship Dry',
  'Ship Freezer',
];
/**
 * Destination types
 */
export const DESTINATION_TYPE_DRY=2
export const DESTINATION_TYPE_FREEZER=3

/**
 * product preference types for customers
 */
export const PRODUCT_PREFERENCE_FRESH = 0
export const PRODUCT_PREFERENCE_FROZEN = 1
export const PRODUCT_PREFERENCE_DELIVERY_CONTAINER_TRAY = 0
export const PRODUCT_PREFERENCE_DELIVERY_CONTAINER_BOX = 1