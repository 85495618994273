import React from 'react'
import { get } from "lodash"
import { Controller } from 'react-hook-form'
import { InputAdornment, TextField } from '@mui/material'
import { Cancel } from '@mui/icons-material'

const InputField = (
  {
    adormentClasses,
    autoComplete,
    autoFocus,
    className,
    control,
    endAdornment,
    errors,
    disabled,
    defaultValue,
    label,
    name,
    rules,
    type,
    variant,
    style,
    helperText,
    startAdornment,
    isClearable,
    clearIcon,
    inputProps,
    clearIconPosition,
    afterClearDefaultValueIs,
    onClear,
    onInput
  },
  props
) => {


  const error = get(errors, name)

  const clear = () => {
    onClear({ [name]: afterClearDefaultValueIs })
  }

  const adormentStyle = { opacity: disabled ? 0.5 : 1 }

  const Adorment = () => {
    return (
      <InputAdornment
        position={
          startAdornment || clearIconPosition === 'start' ? 'start' : 'end'
        }
        className={adormentClasses}
      >
        {startAdornment || clearIconPosition === 'start' ? (
          <span
            onClick={disabled || !isClearable ? null : clear}
            style={adormentStyle}
          >
            {startAdornment || clearIcon}
          </span>
        ) : (
          <span
            onClick={disabled || !isClearable ? null : clear}
            style={adormentStyle}
          >
            {endAdornment || clearIcon}
          </span>
        )}
      </InputAdornment>
    )
  }
  
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <TextField
          fullWidth
          {...field}
          id={name}
          type={type}
          label={label}
          variant={variant}
          helperText={
            error ? (
              <span role='alert'>{error.message || 'This is required'}</span>
            ) : (
              <span role='log'>{helperText}</span>
            )
          }
          autoComplete={autoComplete && name}
          autoFocus={autoFocus}
          className={className}
          style={style}
          error={(error) ? true : false}
          InputProps={{
            endAdornment: ((isClearable && clearIconPosition === 'end') ||
              endAdornment) && <Adorment />,
            startAdornment: ((isClearable && clearIconPosition === 'start') ||
              startAdornment) && <Adorment />,
              style:{fontSize:'20px',lineHeight:'40px',marginTop:'24px'},
            ...inputProps,
          }}
          InputLabelProps={{style: {fontSize: (disabled)?'18px':'28px'}}}
          disabled={disabled}
          onInput={onInput}
        />
      )}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      // defaultValue={value}
      autoFocus={autoFocus}
      // {...props}
    />
  )
}

InputField.defaultProps = {
  name: '',
  label: '',
  control: {},
  rules: {},
  errors: {},
  type: 'text',
  variant: 'standard',
  defaultValue: '',
  className: '',
  style: {},
  autoComplete: '',
  autoFocus: false,
  helperText: null,
  disabled: false,
  adormentClasses: '',
  startAdornment: null,
  endAdornment: null,
  inputProps: {},
  props: {},
  isClearable: false,
  clearIconPosition: 'end',
  clearIcon: <Cancel color='action' cursor='default' />,
  afterClearDefaultValueIs: 0,
}

export default InputField
