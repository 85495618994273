import { rgmpApi } from './base.service.js'
import { FORM_GROUP_REDUCER_KEY,TOKEN_STORAGE_KEY } from 'helpers/constants'
import { API_PATH_FORM_GROUP } from 'helpers/constants/url.constants'

export const formGroupApi = rgmpApi.injectEndpoints({
  endpoints: (builder) => ({
    getFormGroups: builder.query({
      query: () => `${API_PATH_FORM_GROUP}/?token=${localStorage.getItem(TOKEN_STORAGE_KEY)}`,
      providesTags:[FORM_GROUP_REDUCER_KEY]
    }),
  }),
})

// Export hooks for usage in functional components
export const { useGetFormGroupsQuery } = formGroupApi
