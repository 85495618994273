import {useState,lazy, Suspense} from 'react';
import { Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { DivRefsProvider } from 'providers/DivRefs';
import Login from 'components/Login'
import Layout from 'components/Layout'

const DocDash = lazy(()=> import ('components/EasyDoc/DocDash/index'))
const EntryForm = lazy(()=> import ('components/EasyDoc/Entries/Form'))
const ListEntries = lazy(()=> import ('components/EasyDoc/Entries'))
const OrderSheet = lazy(()=> import ('components/Orderwave/OrderSheet'))
const FutureOrders = lazy(()=> import ('components/Orderwave/FutureOrders'))
const RedirectToLoginCheck = lazy(()=> import ('./redirect'))

function AppRoutes() {
  
  const [isAuthLoaded,setIsAuthLoaded]=useState(false)
  return (
    <>
      <ToastContainer />
      <DivRefsProvider>
      <RedirectToLoginCheck setIsAuthLoaded = {setIsAuthLoaded} />
      <Routes>
        <Route path='/login' element={<Login />} />
        {
          isAuthLoaded &&
          
          <Route path='/' element={<Layout/>}>
            <Route path='admin/dashboard' element={<></>} />
          <Route path='easydoc/'>
            <Route path='dash/:groupId' element={<Suspense fallback={<p> Loading...</p>}><DocDash /></Suspense>} />
            <Route path='entries/'>
              <Route path='' element={<Suspense fallback={<p> Loading...</p>}><ListEntries /></Suspense>} />
              <Route path='edit/:entryId' element={<Suspense fallback={<p> Loading...</p>}><EntryForm /></Suspense>} />
              <Route path='create/:formId' element={<Suspense fallback={<p> Loading...</p>}><EntryForm /></Suspense>} />
            </Route>{/** entries */}
          </Route>
          <Route path='orderwave/'>
            <Route path='order-sheet/*' element={<Suspense fallback={<p> Loading...</p>}><OrderSheet /></Suspense>} />
            <Route path='future-orders/' element={<Suspense fallback={<p> Loading...</p>}><FutureOrders /></Suspense>} />
          </Route>
        </Route>
        }
      </Routes>
      {/* </Suspense> */}
      </DivRefsProvider>
    </>
  )
}

// AppRoutes.whyDidYouRender = true
export default AppRoutes
