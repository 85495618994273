import * as React from 'react';
import { Controller } from 'react-hook-form'
import { get } from "lodash"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormGroup, FormHelperText } from '@mui/material';

function InputAutocomplete({name,label,control,rules,errors,defaultValue,options,disabled,helperText}) {
    const error = get(errors, name)
  return (
    <FormGroup>
        <Controller
          name={name}
          render={({ field }) => (
            <Autocomplete
                disablePortal
                id={name}
                options={options}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label={label} />}
                onChange={(e,o) => !!o && field.onChange(o.value)}
            />
          )}
          control={control}
          defaultValue={defaultValue}
          rules={rules}
        />
    <FormHelperText id="my-helper-text">{
          error ? (
            <span role='alert'>{error.message || 'This is required'}</span>
          ) : (
            <span role='log'>{helperText}</span>
          )
        }</FormHelperText>
  </FormGroup>
  );
}

InputAutocomplete.defaultProps = {
    name: '',
    label: '',
    control: {},
    rules: {},
    errors: {},
    helperText: null,
    disabled: false,
    defaultValue:'',
  }
  
  export default InputAutocomplete