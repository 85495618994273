import { MODE_EASYDOC,MODE_ORDERWAVE,FORM_GROUP_REDUCER_KEY,TOKEN_STORAGE_KEY } from 'helpers/constants'
import { ROUTE_PATH_DOC_DASH,ROUTE_PATH_ORDER_SHEET,ROUTE_PATH_FUTURE_ORDER } from 'helpers/constants/url.constants';
  import { getAccessLevels } from 'helpers/utils';
import { useGetFormGroupsQuery } from 'store/services/formGroups'

import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import UpcomingTwoToneIcon from '@mui/icons-material/UpcomingTwoTone';

function useLinks () {
  const token = localStorage.getItem(TOKEN_STORAGE_KEY)
  const accessLevels = getAccessLevels()
  const {data:formGroups} = useGetFormGroupsQuery(FORM_GROUP_REDUCER_KEY,{skip:!token})
  const linkObj={
    [MODE_EASYDOC]:[],
    [MODE_ORDERWAVE]:[],
  }
  if(accessLevels?.docs.read) linkObj[MODE_EASYDOC].push({ text:'Entries', icon:<MenuBookTwoToneIcon />, children:[],})
  
  if(accessLevels?.ordersheet.read) linkObj[MODE_ORDERWAVE].push({ text:'Order Sheet', icon:<ReceiptTwoToneIcon />, url:`${ROUTE_PATH_ORDER_SHEET}` })
  if(accessLevels?.ordersheet.read) linkObj[MODE_ORDERWAVE].push({ text:'Upcoming Orders', icon:<UpcomingTwoToneIcon />, url:`${ROUTE_PATH_FUTURE_ORDER}` })
  
  formGroups && formGroups.map(({ id, name }) => {
    const EntryLink = linkObj[MODE_EASYDOC].find(o=>o.text === 'Entries')
    if(!accessLevels.doc_groups[id].read) return null
    return EntryLink.children.push({
      text:name,
      icon:<MenuBookTwoToneIcon />,
      url:`${ROUTE_PATH_DOC_DASH}/${id}`,
      id
    })
  })
  
  return linkObj
}
export default useLinks
