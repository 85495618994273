// import { composeWithDevTools } from '@redux-devtools/extension'
import { configureStore } from '@reduxjs/toolkit'
import { handleError } from './middlewares'
import { rgmpApi } from './services/base.service'

// const composedEnhancers = composeWithDevTools()

const store = configureStore({
  reducer: {
    [rgmpApi.reducerPath]: rgmpApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      // logger,
      handleError,
      rgmpApi.middleware
    ),
    devTools: process.env.NODE_ENV !== 'production',
})

export default store
