import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  Typography,
  Fade,
  Grid,
} from '@mui/material'

import {
  Visibility,
  VisibilityOff,
  EditOutlined,
} from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import logo from '../../assets/img/logo.png'
import { InputField, BasicButton } from 'components/form_control'

import {
  TEXT_INPUT_MIN_LENGTH_RULE,
  PASSWORD_INPUT_MIN_LENGTH_RULE,
  TOKEN_STORAGE_KEY,
  USER_STORAGE_KEY
} from '../../helpers/constants'
import { useLoginMutation } from 'store/services/auth'

function Login(){
  const [
    login, // This is the mutation trigger
    { isLoading: loading }, // This is the destructured mutation result
  ] = useLoginMutation()
  const navigate = useNavigate()
  let [step, setStep] = useState(1)
  let [user, setUser] = useState({ username: null })
  let [visible, setVisible] = useState(false)
  let [edit, setEdit] = useState(false)
  let {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  let onSubmit = async (data) => {
    if (step === 1) {
      setUser({ ...data })
      setStep(2)
    } else {
      let body = { ...user, ...data }
      login(body).unwrap().then((data)=>{
        navigate(data.homepage ?? '/')
      })
    }
  }

  useEffect(() => {//wipe out local storage to "logout"
    localStorage.removeItem(TOKEN_STORAGE_KEY)
    localStorage.removeItem(USER_STORAGE_KEY)
  }, [])

  return (
    <Container maxWidth='sm'>
        <form
          noValidate
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
      <Grid container spacing={5}>
        <Grid item xs={6} md={3}><img style={{width:'150px',marginTop:'40px'}} src={logo} alt='Logo' /></Grid>
        <Grid item xs={6} md={9}><h2 style={{textAlign:'right'}}>Login</h2></Grid>
        <Grid item xs={12}>
        {step === 1 ? (
                <InputField
                  name='username'
                  label='User Name'
                  control={control}
                  rules={{ required: true, ...TEXT_INPUT_MIN_LENGTH_RULE }}
                  errors={errors}
                  autoFocus={true}
                  defaultValue={user.username ? user.username : ''}
                  helperText='Minimun 2 Characters'
                  autoComplete='username'
                />
            ) : (
              <Fade in><div>
                <Typography
                    variant='h6'
                    gutterBottom
                    onMouseOver={() => setEdit(true)}
                    onMouseLeave={() => setEdit(false)}
                    style={{ color: loading && '#c3c3c3ab' }}
                  >
                    {user.username}
                    {edit && !loading && (
                      <EditOutlined
                        onClick={() => {
                          setStep(1)
                          setEdit(false)
                        }}
                      />
                    )}
                  </Typography>
                  <InputField
                    name='password'
                    label='Password'
                    type={visible ? 'text' : 'password'}
                    control={control}
                    rules={{
                      required: true,
                      ...PASSWORD_INPUT_MIN_LENGTH_RULE,
                    }}
                    errors={errors}
                    autoFocus={true}
                    style={{ margin: '0 8px 8px' }}
                    autoComplete='current-password'
                    disabled={loading}
                    endAdornment={
                      !visible ? (
                        <VisibilityOff onClick={() => setVisible(true)} />
                      ) : (
                        <Visibility onClick={() => setVisible(false)} />
                      )
                    }
                  />
              </div></Fade>
            )}
        </Grid>
        <Grid item xs={12}><BasicButton
          fullWidth={true}
          type='submit'
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
        >
          {step === 1 ? 'Next' : 'Login'}
        </BasicButton></Grid>
      </Grid>
      </form>
    </Container>
  )
}

export default Login
