import { get } from "lodash"
import { Controller } from 'react-hook-form'
import { Checkbox,FormControlLabel,FormGroup,FormHelperText } from '@mui/material'

const InputCheckbox = (
  {
    adormentClasses,
    autoComplete,
    autoFocus,
    className,
    control,
    endAdornment,
    errors,
    disabled,
    label,
    name,
    value,
    defaultValue,
    rules,
    type,
    variant,
    style,
    helperText,
    startAdornment,
    isClearable,
    clearIcon,
    inputProps,
    clearIconPosition,
    afterClearDefaultValueIs,
    onClear,
    onInput
  },
  props
) => {
  const error = get(errors, name)
  return (
    <FormGroup>
      <FormControlLabel 
          control={
            <Controller
            name={name}
            render={({ field }) => (
                <Checkbox {...field} 
                  checked={(field.value)?true:false} 
                  onChange={(e) => field.onChange(e.target.checked)}
                />
            )}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
          />
        } 
          label={label} 
          disabled={disabled}
          error={error ? (
              <span role='alert'>{error.message || 'This is required'}</span>
            ) : (
              <span role='log'>{helperText}</span>
            )}
      />
      <FormHelperText id="my-helper-text">{
            error ? (
              <span role='alert'>{error.message || 'This is required'}</span>
            ) : (
              <span role='log'>{helperText}</span>
            )
          }</FormHelperText>
    </FormGroup>
  )
}

InputCheckbox.defaultProps = {
  name: '',
  label: '',
  value:false,
  control: {},
  rules: {},
  errors: {},
  helperText: null,
  disabled: false,
  defaultValue:false,
}

export default InputCheckbox
