import React from 'react';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chip from "@mui/material/Chip";

const PREFIX = 'MultiSelect2';
const classes = {
    formControl: `${PREFIX}-formControl`,
    chips: `${PREFIX}-chips`,
    chip: `${PREFIX}-chip`,
    noLabel: `${PREFIX}-noLabel`,
}

const ITEM_HEIGHT = 148;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function MultipleSelect2(props) {
    const { label, name, options, handleInputChange, value } = props;

    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-checkbox-label">
                    {label}
                </InputLabel>
                <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    name={name}
                    multiple
                    value={value}
                    onChange={handleInputChange}
                    input={<Input />}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {selected.map((value) => (
                                // eslint-disable-next-line
                                options.map((item) => {
                                    if (item.value === value) {
                                        return <Chip key={value} label={item.label} className={classes.chip} />
                                    }
                                })
                            ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <Checkbox checked={value.indexOf(option.value) > -1} />
                            <ListItemText primary={option.label} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
